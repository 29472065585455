import revive_payload_client_Egfh8ym2o7 from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_@types+node@20.12.2_@unocss+reset@0.58.9_axios@0.24.0_eslint@8.57.0_floating-vue@_ebfv7p5ajuwpzrv6b6h3yec634/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_JAZZQmqlY8 from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_@types+node@20.12.2_@unocss+reset@0.58.9_axios@0.24.0_eslint@8.57.0_floating-vue@_ebfv7p5ajuwpzrv6b6h3yec634/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_QwBqNgFyOv from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_@types+node@20.12.2_@unocss+reset@0.58.9_axios@0.24.0_eslint@8.57.0_floating-vue@_ebfv7p5ajuwpzrv6b6h3yec634/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_6gdvvoLLjT from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@2.2.11_@nuxt+devtools@1.1.5_@vue+compiler-core@3.4.21_axios@0.24.0_nuxt@3.11_edfd54hvyf4o24yjmf6p5fzwou/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_oOnkm91dqm from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_@types+node@20.12.2_@unocss+reset@0.58.9_axios@0.24.0_eslint@8.57.0_floating-vue@_ebfv7p5ajuwpzrv6b6h3yec634/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_iTS7lnhnnl from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_@types+node@20.12.2_@unocss+reset@0.58.9_axios@0.24.0_eslint@8.57.0_floating-vue@_ebfv7p5ajuwpzrv6b6h3yec634/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_3hU3TuRAEo from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.1_typescript@5.4.3_vue@3.4.21/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_NA9TZjKkGx from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_@types+node@20.12.2_@unocss+reset@0.58.9_axios@0.24.0_eslint@8.57.0_floating-vue@_ebfv7p5ajuwpzrv6b6h3yec634/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_Y00ChtiT1Q from "/vercel/path0/node_modules/.pnpm/nuxt-bugsnag@7.2.3/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import titles_XIzP3bA6wx from "/vercel/path0/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.8_@lezer+common@1.2.1_@nuxt+devtools@1.1.5_@unhead+shared@1.9.4_@vue+com_bgfhinczledv422u2ca3zzp7eu/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.mjs";
import defaults_9ga1nCMs78 from "/vercel/path0/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.8_@lezer+common@1.2.1_@nuxt+devtools@1.1.5_@unhead+shared@1.9.4_@vue+com_bgfhinczledv422u2ca3zzp7eu/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.mjs";
import siteConfig_AsNTYo4NvZ from "/vercel/path0/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.5_@nuxt+devtools@1.1.5_@vue+compiler-core@3.4.21_axios@0.24.0_n_n34l2cp2vh6vr4ebcrbhlue43u/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.mjs";
import inferSeoMetaPlugin_poE7A4qN6x from "/vercel/path0/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.5_@nuxt+devtools@1.1.5_@vue+compiler-core@3.4.21_axios@0.24.0_n_n34l2cp2vh6vr4ebcrbhlue43u/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.mjs";
import plugin_6p81hRGQjp from "/vercel/path0/node_modules/.pnpm/@nuxt+image@1.4.0_ioredis@5.3.2/node_modules/@nuxt/image/dist/runtime/plugin.mjs";
import slideovers_XdLCxnne2M from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.15.1_axios@0.24.0_nuxt@3.11.1_vite@5.2.7_vue@3.4.21/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.mjs";
import modals_wHLTOQ0KZT from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.15.1_axios@0.24.0_nuxt@3.11.1_vite@5.2.7_vue@3.4.21/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_ppQDzBwSBt from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.15.1_axios@0.24.0_nuxt@3.11.1_vite@5.2.7_vue@3.4.21/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_jWG4WARjX8 from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.3.3/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import chunk_reload_client_fJvd6no8Si from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_@types+node@20.12.2_@unocss+reset@0.58.9_axios@0.24.0_eslint@8.57.0_floating-vue@_ebfv7p5ajuwpzrv6b6h3yec634/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import currentUser_8zeWVUidVY from "/vercel/path0/plugins/currentUser.ts";
import customer_EzwsfA6VNk from "/vercel/path0/plugins/customer.ts";
import draggable_client_TCXd72fVBT from "/vercel/path0/plugins/draggable.client.js";
import order_ZlGb7wk7U9 from "/vercel/path0/plugins/order.ts";
import products_NWDUVOwMVL from "/vercel/path0/plugins/products.ts";
import settings_ZJy0LLHwcO from "/vercel/path0/plugins/settings.ts";
import store_9xNuDHGAVU from "/vercel/path0/plugins/store.ts";
import theme_zupea9J243 from "/vercel/path0/plugins/theme.ts";
import utilities_Hys3Y2ToiA from "/vercel/path0/plugins/utilities.ts";
import vue_easy_lightbox_client_7pQ4IwX770 from "/vercel/path0/plugins/vue_easy_lightbox.client.js";
export default [
  revive_payload_client_Egfh8ym2o7,
  unhead_JAZZQmqlY8,
  router_QwBqNgFyOv,
  _0_siteConfig_6gdvvoLLjT,
  payload_client_oOnkm91dqm,
  check_outdated_build_client_iTS7lnhnnl,
  plugin_vue3_3hU3TuRAEo,
  components_plugin_KR1HBZs4kY,
  prefetch_client_NA9TZjKkGx,
  plugin_Y00ChtiT1Q,
  titles_XIzP3bA6wx,
  defaults_9ga1nCMs78,
  siteConfig_AsNTYo4NvZ,
  inferSeoMetaPlugin_poE7A4qN6x,
  plugin_6p81hRGQjp,
  slideovers_XdLCxnne2M,
  modals_wHLTOQ0KZT,
  colors_ppQDzBwSBt,
  plugin_client_jWG4WARjX8,
  chunk_reload_client_fJvd6no8Si,
  currentUser_8zeWVUidVY,
  customer_EzwsfA6VNk,
  draggable_client_TCXd72fVBT,
  order_ZlGb7wk7U9,
  products_NWDUVOwMVL,
  settings_ZJy0LLHwcO,
  store_9xNuDHGAVU,
  theme_zupea9J243,
  utilities_Hys3Y2ToiA,
  vue_easy_lightbox_client_7pQ4IwX770
]