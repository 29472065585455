import validate from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_@types+node@20.12.2_@unocss+reset@0.58.9_axios@0.24.0_eslint@8.57.0_floating-vue@_ebfv7p5ajuwpzrv6b6h3yec634/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/vercel/path0/middleware/auth.global.ts";
import manifest_45route_45rule from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_@types+node@20.12.2_@unocss+reset@0.58.9_axios@0.24.0_eslint@8.57.0_floating-vue@_ebfv7p5ajuwpzrv6b6h3yec634/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "home-redirect": () => import("/vercel/path0/middleware/home_redirect.ts")
}