
import * as weservRuntime$brMQVFD5ut from '/vercel/path0/node_modules/.pnpm/@nuxt+image@1.4.0_ioredis@5.3.2/node_modules/@nuxt/image/dist/runtime/providers/weserv'
import * as vercelRuntime$uqMkIfLtcF from '/vercel/path0/node_modules/.pnpm/@nuxt+image@1.4.0_ioredis@5.3.2/node_modules/@nuxt/image/dist/runtime/providers/vercel'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {
    "prod_small_thumbnail": {
      "modifiers": {
        "format": "webp",
        "width": 150,
        "height": 150,
        "fit": "fill"
      }
    },
    "prod_tiny_thumbnail": {
      "modifiers": {
        "format": "webp",
        "width": 36,
        "height": 48,
        "fit": "fill"
      }
    }
  },
  "provider": "vercel",
  "domains": [
    "backend.bazari.it",
    "bazari-products-final-v2.fra1.cdn.digitaloceanspaces.com"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp",
    "avif",
    "png",
    "webp"
  ]
}

imageOptions.providers = {
  ['weserv']: { provider: weservRuntime$brMQVFD5ut, defaults: {"baseURL":"https://bash.bazari.it"} },
  ['vercel']: { provider: vercelRuntime$uqMkIfLtcF, defaults: {} }
}
        