import { default as indexkppHktB81xMeta } from "/vercel/path0/pages/auth/index.vue?macro=true";
import { default as indexwxlq7WfK5WMeta } from "/vercel/path0/pages/categories/index.vue?macro=true";
import { default as _91id_93yZl9UHHtRdMeta } from "/vercel/path0/pages/collections/[id].vue?macro=true";
import { default as _91id_93YFKWp2r25hMeta } from "/vercel/path0/pages/customers/[id].vue?macro=true";
import { default as _91id_93NPMaMOTU8OMeta } from "/vercel/path0/pages/customers/account_requests/[id].vue?macro=true";
import { default as createRZ9JNWIjsoMeta } from "/vercel/path0/pages/customers/account_requests/create.vue?macro=true";
import { default as indexVKyygfOaWmMeta } from "/vercel/path0/pages/customers/account_requests/index.vue?macro=true";
import { default as indexs9KBIDgIMmMeta } from "/vercel/path0/pages/customers/index.vue?macro=true";
import { default as indexwlpiVZXNlBMeta } from "/vercel/path0/pages/discounts/index.vue?macro=true";
import { default as index6acdBzrxqJMeta } from "/vercel/path0/pages/gift-cards/index.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as indexFNhsUZOYfgMeta } from "/vercel/path0/pages/invite/index.vue?macro=true";
import { default as _91id_93tvDo5AKAx4Meta } from "/vercel/path0/pages/orders/[id].vue?macro=true";
import { default as indexan9ZrZAr6jMeta } from "/vercel/path0/pages/orders/index.vue?macro=true";
import { default as indexIwBpLtCJW6Meta } from "/vercel/path0/pages/pricing/index.vue?macro=true";
import { default as _91id_93HwlN2SmMiuMeta } from "/vercel/path0/pages/products/[id].vue?macro=true";
import { default as indexDJjD9NcZ4YMeta } from "/vercel/path0/pages/products/index.vue?macro=true";
import { default as api_45key_45management6YNH7oSpCfMeta } from "/vercel/path0/pages/settings/api-key-management.vue?macro=true";
import { default as indexDzZVWTw7WMMeta } from "/vercel/path0/pages/settings/faq/index.vue?macro=true";
import { default as indexKIRFIU1ID4Meta } from "/vercel/path0/pages/settings/index.vue?macro=true";
import { default as index1yOk2hL6g6Meta } from "/vercel/path0/pages/settings/store/index.vue?macro=true";
import { default as team4ptG6EaRhWMeta } from "/vercel/path0/pages/settings/team.vue?macro=true";
export default [
  {
    name: indexkppHktB81xMeta?.name ?? "auth",
    path: indexkppHktB81xMeta?.path ?? "/auth",
    meta: indexkppHktB81xMeta || {},
    alias: indexkppHktB81xMeta?.alias || [],
    redirect: indexkppHktB81xMeta?.redirect,
    component: () => import("/vercel/path0/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: indexwxlq7WfK5WMeta?.name ?? "categories",
    path: indexwxlq7WfK5WMeta?.path ?? "/categories",
    meta: indexwxlq7WfK5WMeta || {},
    alias: indexwxlq7WfK5WMeta?.alias || [],
    redirect: indexwxlq7WfK5WMeta?.redirect,
    component: () => import("/vercel/path0/pages/categories/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93yZl9UHHtRdMeta?.name ?? "collections-id",
    path: _91id_93yZl9UHHtRdMeta?.path ?? "/collections/:id()",
    meta: _91id_93yZl9UHHtRdMeta || {},
    alias: _91id_93yZl9UHHtRdMeta?.alias || [],
    redirect: _91id_93yZl9UHHtRdMeta?.redirect,
    component: () => import("/vercel/path0/pages/collections/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93YFKWp2r25hMeta?.name ?? "customers-id",
    path: _91id_93YFKWp2r25hMeta?.path ?? "/customers/:id()",
    meta: _91id_93YFKWp2r25hMeta || {},
    alias: _91id_93YFKWp2r25hMeta?.alias || [],
    redirect: _91id_93YFKWp2r25hMeta?.redirect,
    component: () => import("/vercel/path0/pages/customers/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93NPMaMOTU8OMeta?.name ?? "customers-account_requests-id",
    path: _91id_93NPMaMOTU8OMeta?.path ?? "/customers/account_requests/:id()",
    meta: _91id_93NPMaMOTU8OMeta || {},
    alias: _91id_93NPMaMOTU8OMeta?.alias || [],
    redirect: _91id_93NPMaMOTU8OMeta?.redirect,
    component: () => import("/vercel/path0/pages/customers/account_requests/[id].vue").then(m => m.default || m)
  },
  {
    name: createRZ9JNWIjsoMeta?.name ?? "customers-account_requests-create",
    path: createRZ9JNWIjsoMeta?.path ?? "/customers/account_requests/create",
    meta: createRZ9JNWIjsoMeta || {},
    alias: createRZ9JNWIjsoMeta?.alias || [],
    redirect: createRZ9JNWIjsoMeta?.redirect,
    component: () => import("/vercel/path0/pages/customers/account_requests/create.vue").then(m => m.default || m)
  },
  {
    name: indexVKyygfOaWmMeta?.name ?? "customers-account_requests",
    path: indexVKyygfOaWmMeta?.path ?? "/customers/account_requests",
    meta: indexVKyygfOaWmMeta || {},
    alias: indexVKyygfOaWmMeta?.alias || [],
    redirect: indexVKyygfOaWmMeta?.redirect,
    component: () => import("/vercel/path0/pages/customers/account_requests/index.vue").then(m => m.default || m)
  },
  {
    name: indexs9KBIDgIMmMeta?.name ?? "customers",
    path: indexs9KBIDgIMmMeta?.path ?? "/customers",
    meta: indexs9KBIDgIMmMeta || {},
    alias: indexs9KBIDgIMmMeta?.alias || [],
    redirect: indexs9KBIDgIMmMeta?.redirect,
    component: () => import("/vercel/path0/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexwlpiVZXNlBMeta?.name ?? "discounts",
    path: indexwlpiVZXNlBMeta?.path ?? "/discounts",
    meta: indexwlpiVZXNlBMeta || {},
    alias: indexwlpiVZXNlBMeta?.alias || [],
    redirect: indexwlpiVZXNlBMeta?.redirect,
    component: () => import("/vercel/path0/pages/discounts/index.vue").then(m => m.default || m)
  },
  {
    name: index6acdBzrxqJMeta?.name ?? "gift-cards",
    path: index6acdBzrxqJMeta?.path ?? "/gift-cards",
    meta: index6acdBzrxqJMeta || {},
    alias: index6acdBzrxqJMeta?.alias || [],
    redirect: index6acdBzrxqJMeta?.redirect,
    component: () => import("/vercel/path0/pages/gift-cards/index.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexFNhsUZOYfgMeta?.name ?? "invite",
    path: indexFNhsUZOYfgMeta?.path ?? "/invite",
    meta: indexFNhsUZOYfgMeta || {},
    alias: indexFNhsUZOYfgMeta?.alias || [],
    redirect: indexFNhsUZOYfgMeta?.redirect,
    component: () => import("/vercel/path0/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93tvDo5AKAx4Meta?.name ?? "orders-id",
    path: _91id_93tvDo5AKAx4Meta?.path ?? "/orders/:id()",
    meta: _91id_93tvDo5AKAx4Meta || {},
    alias: _91id_93tvDo5AKAx4Meta?.alias || [],
    redirect: _91id_93tvDo5AKAx4Meta?.redirect,
    component: () => import("/vercel/path0/pages/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: indexan9ZrZAr6jMeta?.name ?? "orders",
    path: indexan9ZrZAr6jMeta?.path ?? "/orders",
    meta: indexan9ZrZAr6jMeta || {},
    alias: indexan9ZrZAr6jMeta?.alias || [],
    redirect: indexan9ZrZAr6jMeta?.redirect,
    component: () => import("/vercel/path0/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: indexIwBpLtCJW6Meta?.name ?? "pricing",
    path: indexIwBpLtCJW6Meta?.path ?? "/pricing",
    meta: indexIwBpLtCJW6Meta || {},
    alias: indexIwBpLtCJW6Meta?.alias || [],
    redirect: indexIwBpLtCJW6Meta?.redirect,
    component: () => import("/vercel/path0/pages/pricing/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93HwlN2SmMiuMeta?.name ?? "products-id",
    path: _91id_93HwlN2SmMiuMeta?.path ?? "/products/:id()",
    meta: _91id_93HwlN2SmMiuMeta || {},
    alias: _91id_93HwlN2SmMiuMeta?.alias || [],
    redirect: _91id_93HwlN2SmMiuMeta?.redirect,
    component: () => import("/vercel/path0/pages/products/[id].vue").then(m => m.default || m)
  },
  {
    name: indexDJjD9NcZ4YMeta?.name ?? "products",
    path: indexDJjD9NcZ4YMeta?.path ?? "/products",
    meta: indexDJjD9NcZ4YMeta || {},
    alias: indexDJjD9NcZ4YMeta?.alias || [],
    redirect: indexDJjD9NcZ4YMeta?.redirect,
    component: () => import("/vercel/path0/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: api_45key_45management6YNH7oSpCfMeta?.name ?? "settings-api-key-management",
    path: api_45key_45management6YNH7oSpCfMeta?.path ?? "/settings/api-key-management",
    meta: api_45key_45management6YNH7oSpCfMeta || {},
    alias: api_45key_45management6YNH7oSpCfMeta?.alias || [],
    redirect: api_45key_45management6YNH7oSpCfMeta?.redirect,
    component: () => import("/vercel/path0/pages/settings/api-key-management.vue").then(m => m.default || m)
  },
  {
    name: indexDzZVWTw7WMMeta?.name ?? "settings-faq",
    path: indexDzZVWTw7WMMeta?.path ?? "/settings/faq",
    meta: indexDzZVWTw7WMMeta || {},
    alias: indexDzZVWTw7WMMeta?.alias || [],
    redirect: indexDzZVWTw7WMMeta?.redirect,
    component: () => import("/vercel/path0/pages/settings/faq/index.vue").then(m => m.default || m)
  },
  {
    name: indexKIRFIU1ID4Meta?.name ?? "settings",
    path: indexKIRFIU1ID4Meta?.path ?? "/settings",
    meta: indexKIRFIU1ID4Meta || {},
    alias: indexKIRFIU1ID4Meta?.alias || [],
    redirect: indexKIRFIU1ID4Meta?.redirect,
    component: () => import("/vercel/path0/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: index1yOk2hL6g6Meta?.name ?? "settings-store",
    path: index1yOk2hL6g6Meta?.path ?? "/settings/store",
    meta: index1yOk2hL6g6Meta || {},
    alias: index1yOk2hL6g6Meta?.alias || [],
    redirect: index1yOk2hL6g6Meta?.redirect,
    component: () => import("/vercel/path0/pages/settings/store/index.vue").then(m => m.default || m)
  },
  {
    name: team4ptG6EaRhWMeta?.name ?? "settings-team",
    path: team4ptG6EaRhWMeta?.path ?? "/settings/team",
    meta: team4ptG6EaRhWMeta || {},
    alias: team4ptG6EaRhWMeta?.alias || [],
    redirect: team4ptG6EaRhWMeta?.redirect,
    component: () => import("/vercel/path0/pages/settings/team.vue").then(m => m.default || m)
  }
]